<template>
    <div style="width: 240px; background: linear-gradient(180deg, #151F32, #0C1422, #166D66);float: left">
        <el-menu
                :default-active="active"
                class="el-menu-vertical-demo"
                @open="handleOpen"
                @close="handleClose"
                :router="true"  >

            <el-menu-item index="/qiheIndex" @click.native="handleSelectMenu('/qiheIndex')">
                <i class="el-icon-menu"></i>
                <span slot="title">基地监测数据</span>
            </el-menu-item>
            <el-menu-item index="/qiheWarn" @click.native="handleSelectMenu('/qiheWarn')">
                <i class="el-icon-document"></i>
                <span slot="title">预警报警记录</span>
            </el-menu-item>
            <el-menu-item index="/qiheHistory" @click.native="handleSelectMenu('/qiheHistory')">
                <i class="el-icon-setting"></i>
                <span slot="title">数据历史记录</span>
            </el-menu-item>
        </el-menu>
    </div>

</template>

<script>
    export default {
        name: "qiheLeftMenu",
        data(){
            return{
                // activerouter:'',
                active: "/qiheIndex",
            }
        },
        created() {
        },
        methods:{

            handleOpen(key, keyPath) {
                console.log(key, keyPath);
            },
            handleClose(key, keyPath) {
                console.log(key, keyPath);
            },
            handleSelectMenu(value){
                this.active=value;
                console.log("handleSelectMenu---"+value)
            }

        },
        mounted() {
            this.$bas.$on("suiji",(i)=>{
                this.active=i;
                console.log("mountediiii----------",i)
            })
        },




    }


</script>

<style scoped>
    body{
        position: relative;
        height: 100%;
        padding: 0;
        margin: 0;
    }
    div{
        bottom: 0px;
        right: 0px;
        left:0px;
        top: 73px;
        position: absolute;
        display: block;

    }
    .el-menu-item:focus, .el-menu-item:hover,
    .el-menu-item .is-active {
        color: white;
        background: rgb(21, 31, 50) !important;
    }
    /deep/.el-menu-item {
        color: white;
    }
    /deep/.el-menu-item.is-active{
        color: white;
        background: linear-gradient(95deg, #1AC575, #0A5B50) !important;
    }
    /deep/.el-icon-menu:before{
        content: url("../assets/basegreenhouse/ic_anv_base_selected.png");
    }
    /deep/.el-icon-document:before{
        content: url("../assets/basegreenhouse/ic_anv_early_selected.png");
    }
    /deep/.el-icon-setting:before{
        content: url("../assets/basegreenhouse/ic_anv_data_selected.png");
    }
    .el-icon-jidi{
        background: url("../assets/basegreenhouse/ic_anv_base_normal.png") no-repeat;
        width: 20px;
        height: 20px;
    }
    .el-icon-jidi .is-active {
        background: url("../assets/basegreenhouse/ic_anv_base_selected.png") no-repeat;

    }
    /deep/
    .el-menu{
        height: 100%;
        background: transparent;
    }


</style>
