<template>
  <div style="background: #006837" id="headTitle">
    <!--物联网-->
    <div class="line" v-if="NavHead ==='thingsInterner'">
      <el-menu
          :default-active="$route.path"
          class="el-menu-demo "
          mode="horizontal"
          router
          @select="handleSelect"
          background-color="#006837"
          text-color="#fff"
          active-text-color="#ffd04b">
        <el-menu-item disabled><img src="" alt=""> <span
            style="vertical-align: middle;margin-left:10px ">智慧农业物联网</span></el-menu-item>
        <el-menu-item index="/index" class=""><img src="" alt=""> <span
            style="vertical-align: middle;margin-left:10px ">首页</span></el-menu-item>
        <el-menu-item v-if="this.$getPermission('/pc/gHouse/getByBid')" index="/home/CanopyManagementList"><span
            style="vertical-align: middle;margin-left:10px ">大棚管理</span></el-menu-item>
        <el-menu-item v-if="this.$getPermission('/pc/videos/getList')" index="/home/videoList"><span
            style="vertical-align: middle;margin-left:10px ">视频管理</span></el-menu-item>
        <el-menu-item v-if="this.$getPermission('/pc/conf/list')" index="/home/warning"><span
            style="vertical-align: middle;margin-left:10px ">报警设置</span></el-menu-item>

        <el-submenu index="/" style="float: right">
          <template slot="title">{{ companyName }}</template>
          <el-menu-item v-if="this.$getPermission('/pc/base/getBase')" index="/user/baseMessage">基地信息</el-menu-item>
          <el-menu-item  index="/login">退出</el-menu-item>
        </el-submenu>
        <el-menu-item index="/" style="float: right"><span
            style="vertical-align: middle;margin-left:10px ">返回系统首页</span></el-menu-item>
        <el-menu-item v-if="this.$getPermission('/pc/error/getListByBid')" index="/home/warningMessage"
                      style="float: right"><span
            style="vertical-align: middle;margin-left:10px ">消息</span></el-menu-item>
      </el-menu>
    </div>
    <!--    农场erp-->
    <div class="line" v-if="NavHead ==='erp'">
      <el-menu
          :default-active="$route.path"
          class="el-menu-demo "
          mode="horizontal"
          router
          @select="handleSelect"
          background-color="#006837"
          text-color="#fff"
          active-text-color="#ffd04b">
        <el-menu-item disabled><img src="" alt=""> <span
            style="vertical-align: middle;margin-left:10px ">农场ERP</span></el-menu-item>
        <el-menu-item   v-if="this.$getPermission('/pc/farmingPurchase/getApplyList') || this.$getPermission('/pc/farmingPurchase/getApproveList') ||  this.$getPermission('/pc/farmingPurchase/getPurchaseList') || this.$getPermission('/pc/farmingStoreIn/getInList') || this.$getPermission('/pc/farmingStore/getList') || this.$getPermission('/pc/farmingStoreRecord/getList')"   index="/erp/agricultural" class=""><img src="" alt=""> <span
            style="vertical-align: middle;margin-left:10px ">农资管理</span></el-menu-item>
        <el-menu-item   v-if="this.$getPermission('/pc/farming/getMyList') || this.$getPermission('/pc/farming/getList')"  index="/erp/farming"><span
            style="vertical-align: middle;margin-left:10px ">农事管理</span></el-menu-item>
        <el-menu-item v-if="this.$getPermission('/pc/productStoreIn/getInList') || this.$getPermission('/pc/productStoreOut/getOutList') || this.$getPermission('/pc/productStore/getList') || this.$getPermission('/pc/productStoreRecord/getList')"  index="/erp/warehouse"><span
            style="vertical-align: middle;margin-left:10px ">仓库管理</span></el-menu-item>
<!--        <el-menu-item index="/erp/checkout"><span-->
<!--            style="vertical-align: middle;margin-left:10px ">检验</span></el-menu-item>-->
        <el-menu-item v-if="this.$getPermission('/pc/signRecord/getList')" index="/erp/SignStatistics"><span
            style="vertical-align: middle;margin-left:10px ">签到统计</span></el-menu-item>
        <el-submenu index="/" style="float: right">
          <template slot="title">{{ companyName }}</template>
          <el-menu-item v-if="this.$getPermission('/pc/base/getBase')" index="/user/baseMessage">基地信息</el-menu-item>
          <el-menu-item  index="/login">退出</el-menu-item>
        </el-submenu>
        <el-menu-item index="/" style="float: right"><span
            style="vertical-align: middle;margin-left:10px ">返回系统首页</span></el-menu-item>
        <el-menu-item v-if="this.$getPermission('/pc/error/getListByBid')" index="/home/warningMessage"
                      style="float: right"><span
            style="vertical-align: middle;margin-left:10px ">消息</span></el-menu-item>
      </el-menu>

    </div>
    <!--    基础信息-->
    <div class="line" v-if="NavHead ==='base'">
      <el-menu
          :default-active="$route.path"
          class="el-menu-demo "
          mode="horizontal"
          router
          @select="handleSelect"
          background-color="#006837"
          text-color="#fff"
          active-text-color="#ffd04b">
        <el-menu-item disabled><img src="" alt=""> <span
            style="vertical-align: middle;margin-left:10px ">基础信息</span></el-menu-item>
        <el-menu-item v-if="this.$getPermission('/pc/gHouse/getList')" index="/basics/land" class=""><img src="" alt="">
          <span
              style="vertical-align: middle;margin-left:10px ">地皮管理</span></el-menu-item>
        <el-menu-item  v-if="this.$getPermission('/pc/equi/getList') || this.$getPermission('/pc/faci/getList')  ||  this.$getPermission('/pc/videos/getList')"
                      index="/basics/device"><span
            style="vertical-align: middle;margin-left:10px ">设备管理</span></el-menu-item>
        <el-menu-item  v-if="this.$getPermission('/pc/role/getList') || this.$getPermission('/pc/user/getList')" index="/basics/personnel"><span
            style="vertical-align: middle;margin-left:10px ">人员管理</span></el-menu-item>
        <el-menu-item  v-if="this.$getPermission('/pc/base/getBase') || this.$getPermission('/pc/farmingCapitalType/getList') || this.$getPermission('/pc/farmingCapital/getList')|| this.$getPermission('/pc/sign/getList')"
                       index="/basics/other"><span
            style="vertical-align: middle;margin-left:10px ">其他</span></el-menu-item>

        <el-submenu index="/" style="float: right">
          <template slot="title">{{ companyName }}</template>
          <el-menu-item v-if="this.$getPermission('/pc/base/getBase')" index="/user/baseMessage">基地信息</el-menu-item>
          <el-menu-item  index="/login">退出</el-menu-item>
        </el-submenu>
        <el-menu-item index="/" style="float: right"><span
            style="vertical-align: middle;margin-left:10px ">返回系统首页</span></el-menu-item>
        <el-menu-item v-if="this.$getPermission('/pc/error/getListByBid')" index="/home/warningMessage"
                      style="float: right"><span
            style="vertical-align: middle;margin-left:10px ">消息</span></el-menu-item>
      </el-menu>
    </div>
    <!--    首页-->
    <div class="line" v-if="NavHead ===''">
      <el-menu
          :default-active="$route.path"
          class="el-menu-demo "
          mode="horizontal"
          router
          @select="handleSelect"
          background-color="#006837"
          text-color="#fff"
          active-text-color="#ffd04b">
        <el-menu-item index="/"><img src="" alt=""> <span
            style="vertical-align: middle;margin-left:10px ">智慧农业</span></el-menu-item>
        <el-submenu index="/" style="float: right">
          <template slot="title">{{ companyName }}</template>
          <el-menu-item v-if="this.$getPermission('/pc/base/getBase')" index="/user/baseMessage">基础信息配置</el-menu-item>
          <el-menu-item index="/login">退出</el-menu-item>
        </el-submenu>
        <el-menu-item  v-if="this.$getPermission('/pc/error/getListByBid')" index="/home/warningMessage"
                       style="float: right"><span
            style="vertical-align: middle;margin-left:10px ">报警信息</span></el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: "headNavMenu",
  data() {
    return {
      activeIndex: '/',
      username: '',
      companyName: '公司',
      NavHead: '',
    };
  },
  watch: {
    "$route"(val, oldval) {
      setTimeout(() => {
        if (val.path !== oldval.path) {
          this.NavHead = localStorage.getItem('changeNavHead')
          if (localStorage.getItem('user_nongye')) {
            this.companyName = JSON.parse(localStorage.getItem('user_nongye')).username
          }
        }

      }, 100)

    }
  },
  mounted: function () {
    // setTimeout(() => {
    this.NavHead = localStorage.getItem('changeNavHead')
    if (localStorage.getItem('user_nongye')) {
      this.companyName = JSON.parse(localStorage.getItem('user_nongye')).username
    }
    if (localStorage.getItem('url_wuliawnag')) {
      this.activeIndex = localStorage.getItem('url_wuliawnag')
    }
    // }, 500)

  },
  computed: {},
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      this.$router.push(key)
      localStorage.setItem('selectIndex', '1')
      localStorage.setItem('url_wuliawnag', key)
      if (key == '/') {
        localStorage.setItem('changeNavHead', '')
      }
    }
  }
}
</script>

<style>
#headTitle .is-active {
  background: #033D21 ! important;

}

@media (max-width: 1550px) {
  .line {
    /*margin-left: 20px;*/
    width: 1200px;
    margin: 0 auto;
  }
}

@media (min-width: 1550px) {
  .line {
    margin: 0 auto;
    background: #006837;
    width: 1200px;
    height: 60px;
  }
}

.head-ttile-name {
  font-size: 16px;
  color: #FFFFFF;
  text-decoration: none;
  line-height: 60px;
  margin-left: 20px;
}

.el-menu-item.is-disabled {
  opacity: 1 !important;
}
</style>
