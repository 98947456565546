import FileSaver from 'file-saver';
import * as XLSX from 'xlsx'
export default {
    // 导出Excel表格
    exportExcel(excelName, tableName) {
        //excelName表示生成excel的文件名     tableName表示表格的id
        var wb = XLSX.utils.table_to_book(document.querySelector(tableName))
        var wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' })
        try {
            FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), excelName)
        } catch (e) {
            if (typeof console !== 'undefined') console.log(e, wbout)
        }
        return wbout
    }
};
