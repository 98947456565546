import Vue from 'vue'
import App from './App.vue'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '../static/element.css'
// import  API from './api/api'
Vue.use(ElementUI);

import router from './router'

Vue.config.productionTip = false;
import Vuex from 'vuex'

Vue.use(Vuex)

import * as echarts from 'echarts'

Vue.prototype.$echarts = echarts
//导出文件
import exportExcel from "./util/exportExcel.js"
Vue.prototype.exportExcelUtils = exportExcel

import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)

import axios from 'axios'
import api from './api/index.js'
import store from "@/state";

import all from '/src/util/all';

Vue.use(all);//将全局函数当做插件来进行注册

// 将API方法绑定到全局
Vue.prototype.$api = api
// 拦截器请求
axios.interceptors.request.use(
    config => {
        if (localStorage.getItem('token')) {
            // 判断是否存在token，如果存在的话，每个http header都加上Authorization
            config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
            // config.headers.token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxMDAxIiwiaWF0IjoxNjIzODk4ODAyLCJleHAiOjE2MjQ1MDM2MDJ9.mYFPFmzmnx8ZGZl5rz62jr4ejaisDVw7KwnS-q5Ath3rlZXoEFM_kwawk3wO79jkZHvepxkt7BcSyiNIqkAXBQ-kisLIeBpwAQNA';
        }
        return config;
    },
    err => {
        return Promise.reject(err);
    })

// 拦截器响应
axios.interceptors.response.use(function (response) { // ①10010 token过期（30天） ②10011 token无效
    // console.log(response,'responseresponseresponse-----------------------------')
    if (response.data.code == 401) {
        window.location.href = "/login";
        ElementUI.Message({
            message: '登录超时,请重新登录',
            type: 'error'
        })
    }
    return response
}, function (error) {
    return Promise.reject(error)
})

new Vue({
    router,
    store,
    render: h => h(App),
    beforeCreate(){
        Vue.prototype.$bas = this;
    },
}).$mount('#app')
