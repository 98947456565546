<template>
  <div id="app">
    <div class="body">
      <head-nav-menu  v-if="!( path==='/login' )&&( path==='/qiheIndex' )"></head-nav-menu>
      <qiheNavMenu
          v-if="!( path==='/login' )&&( path==='/qiheIndex' )
          ||!( path==='/login' )&&( path==='/grennhouse' )
          ||!( path==='/login' )&&( path==='/home/grennhouseDetails' )
          ||!( path==='/login' )&&( path==='/qiheHistory' )
          ||!( path==='/login' )&&( path==='/qiheHistoryDetails' )
          ||!( path==='/login' )&&( path==='/qiheWarn' )
          ||!( path==='/login' )&&( path==='/home/qiheGrennhouseDetails' )
          ">
      </qiheNavMenu>
      <qihe-left-menu  @aaa='bbb' v-if="!( path==='/login' )&&( path==='/qiheIndex' )
          ||!( path==='/login' )&&( path==='/grennhouse' )
          ||!( path==='/login' )&&( path==='/home/grennhouseDetails' )
          ||!( path==='/login' )&&( path==='/qiheHistory' )
          ||!( path==='/login' )&&( path==='/qiheHistoryDetails' )
          ||!( path==='/login' )&&( path==='/qiheWarn' )
          ||!( path==='/login' )&&( path==='/home/qiheGrennhouseDetails'
          )

      "></qihe-left-menu>
      <router-view v-if="isRouterAlice" :class="path ==='/login' ? 'page2' :'page'"></router-view>
    </div>
  </div>
</template>

<script>
import headNavMenu from "@/components/headNavMenu";
import qiheNavMenu from "@/components/qiheNavMenu";
import QiheLeftMenu from "./components/qiheLeftMenu";

export default {
  name: 'App',
  provide() {
    return {
      reload: this.reload
    }
  },
  components: {
    QiheLeftMenu,
    headNavMenu,
    qiheNavMenu,
  },
  data() {
    return {
      path: '',
      isRouterAlice: true
    }
  },
  mounted() {
    this.path = this.$router.path;
    console.log('路由', this.path)
    console.log(!(this.path === '/login') && (this.path === '/qiheIndex'))
  },
  methods: {
    bbb(e){
        console.log(e)
    },
    reload() {
      this.isRouterAlice = false
      this.$nextTick(function () {
        this.isRouterAlice = true
      })
    }
  },
  watch: {
    $route(to) {
      this.path = to.path
    }
  }
}
</script>

<style>
/*@media (min-width: 1200px) {*/
/*  .page {*/
/*    padding: 0 360px;*/
/*    background: #F1F3F5;*/
/*    */
/*  }*/
/*}*/
/*@media (max-width: 1200px) {*/
/*  .page {*/
/*    background: #F1F3F5;*/
/*  }*/
/*}*/
.page {
  width: 1200px;
  margin: 0 auto;
}

.page2 {
  width: 100%;
  min-width: 1200px;
  background-size: cover;
}

.body {
  width: 100%;
  min-width: 1200px;
  background: #E3EBED;
  min-height: 100vh;
}

.dialog-button {
  height: 60px;
  background: #009400;
  border-radius: 30px;
  width: 80%;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FEFEFE;
  margin-top: 30px;
}
</style>
