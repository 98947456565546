import Vue from 'vue'
import VueRouter from 'vue-router'
import api from '../api/index'
import store from "@/state";
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'login',
        component: () => import('../views/user/login')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/user/login')
    },{
        path: '/base',
        name: 'base',
        component: () => import('../views/base')
    }, {
        path: '/blankPage',
        name: 'blankPage',
        component: () => import('../views/blankPage')
    },{
        path: '/index',
        name: 'index',
        component: () => import('../views/home/index')
    }, {
        path: '/home/CanopyManagementList',
        name: 'CanopyManagementList',
        component: () => import('../views/home/CanopyManagementList')
    }, {
        path: '/home/CanopyManagement',
        name: 'CanopyManagement',
        component: () => import('../views/home/CanopyManagement')
    }, {
        path: '/home/CanopyManagementDis',
        name: 'CanopyManagementDis',
        component: () => import('../views/home/canopyManagementDIs')
    }, {
        path: '/home/videoList',
        name: 'videoList',
        component: () => import('../views/home/videoList')
    }, {
        path: '/home/warning',
        name: 'warning',
        component: () => import('../views/home/warningList')
    }, {
        path: '/user/baseMessage',
        name: 'baseMessage',
        component: () => import('../views/user/baseMessage')
    }, {
        path: '/user/greenhouseMessage',
        name: 'greenhouseMessage',
        component: () => import('../views/user/greenhouseMessage')
    }, {
        path: '/user/controllerMessage',
        name: 'controllerMessage',
        component: () => import('../views/user/controllerMessage')
    }, {
        path: '/user/deviceMessage',
        name: 'deviceMessage',
        component: () => import('../views/user/deviceMessage')
    }, {
        path: '/user/monitoringMessage',
        name: 'monitoringMessage',
        component: () => import('../views/user/monitoringMessage')
    }, {
        path: '/home/warningMessage',
        name: 'warningMessage',
        component: () => import('../views/home/warningMessage')
    }, {
        path: '/home/middlePage',
        name: 'middlePage',
        component: () => import('../views/home/middlePage')
    }, {
        path: '/erp/agricultural',
        name: 'agricultural',
        component: () => import('../views/erp/agricultural')
    }, {
        path: '/erp/farming',
        name: 'farming',
        component: () => import('../views/erp/farming')
    }, {
        path: '/erp/warehouse',
        name: 'warehouse',
        component: () => import('../views/erp/warehouse')
    }, {
        path: '/erp/checkout',
        name: 'checkout',
        component: () => import('../views/erp/checkout')
    }, {
        path: '/erp/SignStatistics',
        name: 'SignStatistics',
        component: () => import('../views/erp/SignStatistics')
    }, {
        path: '/basics/land',
        name: 'land',
        component: () => import('../views/basics/land')
    }, {
        path: '/basics/device',
        name: 'device',
        component: () => import('../views/basics/device')
    }, {
        path: '/basics/personnel',
        name: 'personnel',
        component: () => import('../views/basics/personnel')
    }, {
        path: '/basics/other',
        name: 'other',
        component: () => import('../views/basics/other')
    }, {
        path: '/qiheIndex',
        name: 'qiheIndex',
        component: () => import('../views/home/qiheIndex')
    }, {
        path: '/grennhouse',
        name: 'grennhouse',
        component: () => import('../views/home/grennhouse')
    },{
        path: '/home/grennhouseDetails',
        name: 'grennhouseDetails',
        component: () => import('../views/home/grennhouseDetails')
    },{
        path: '/home/qiheGrennhouseDetails',
        name: 'qiheGrennhouseDetails',
        component: () => import('../views/home/qiheGrennhouseDetails')
    }, {
        path: '/qiheHistory',
        name: 'qiheHistory',
        component: () => import('../views/home/qiheHistory')
    }, {
        path: '/qiheHistoryDetails',
        name: 'qiheHistoryDetails',
        component: () => import('../views/home/qiheHistoryDetails')
    }, {
        path: '/qiheWarn',
        name: 'qiheWarn',
        component: () => import('../views/home/qiheWarn')
    }
]


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next)=>{
    if(to.query.username){
        let params = {"mobile": to.query.username, "password": to.query.password}
        api.post('login', params, res => {
            if (res.code === 0&&res.user.companyType === '4') {
                localStorage.setItem('token', res.token)
                localStorage.setItem('changeNavHead','')
                localStorage.setItem('storageUser', JSON.stringify(res.user))
                localStorage.setItem('nickName', res.user.nickName)
                store.dispatch("storageUser", res.user)
                localStorage.setItem('permissionList', JSON.stringify(res.user.urlList))
                router.replace('/qiheIndex')
                localStorage.setItem('changeNavHead','jidi')
            }else if (res.user.companyType !='4'){
                this.$message('您没有权限登录')
            }
        })
    }
    next()
})
export default router
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
