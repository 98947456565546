<template>
  <div style="background-image: linear-gradient(93deg, #082635, #0C1320,#166D66);" id="headTitle1">
    <div class="line" v-if="NavHead ==='jidi'">
      <el-menu
          :default-active="$route.path"
          class="el-menu-demo "
          mode="horizontal"
          router
          @select="handleSelect"
          text-color="#fff"
          >
        <el-menu-item index="/qiheIndex"><span
            style="vertical-align: middle;margin-left:10px;color: white;font-size:28px;">产地环境监测系统</span></el-menu-item>
<!--        <el-menu-item  @click="fanhui" style="float: right;wisth:231px;height:73px;padding-right: 0" >-->
<!--          <div class="fanhui">-->
<!--            <img style="display: inline-block; margin-left: 52px;" src="../assets/basegreenhouse/home_return.png">-->
<!--            <span style="vertical-align: middle;margin-left:10px; color: white; font-size: 18px; display: inline-flex; text-align: center;">-->
<!--            返回大屏-->
<!--          </span>-->
<!--          </div>-->

<!--        </el-menu-item>-->
        <el-menu-item style="float: right;wisth:231px;height:73px;">
          <span style="vertical-align: middle;color: #F5F7FF; opacity: 0.3;">|</span>
          <span style="vertical-align: middle;margin-left:33px; color: white; font-size: 18px;  ">{{day | timeFormater('YYYY-MM-DD')}}</span></el-menu-item>

        <el-submenu index="/" style="float: right" icon="el-icon-sold-out" >
          <template slot="title">
            <span style="vertical-align: middle;color: #F5F7FF; opacity: 0.3;margin-right: 33px;">|</span>
            <i class="el-icon-menu"></i><span style="margin-left: 10px; font-size: 16px;">{{ nickName }}</span></template>
          <el-menu-item style=" color:#333333; " v-if="this.$getPermission('/pc/base/getBase')" index="/user/baseMessage">基地信息</el-menu-item>
          <el-menu-item style=" color:#333333; "  @click="tuichu()">退出</el-menu-item>
        </el-submenu>
<!--        <el-menu-item index="/" style="float: right"><span-->
<!--            style="vertical-align: middle;margin-left:10px ">返回系统首页</span></el-menu-item>-->
<!--        v-if="this.$getPermission('/pc/error/getListByBid')"-->
<!--        index="/home/warningMessage"-->
        <el-menu-item   @click="dialogVisible=true" style="float: right;position: relative;">
          <div v-show="size>0" style="border-radius: 50%;color: white; font-size: 14px;text-align: center;min-width: 20px;padding: 2px; height:20px;position: inherit;line-height:21px; display:inline-block;
                top: -10px;right: -40px;background-color: #FF363A;">{{size}}</div>
          <img style="margin-right: 20px" src="../assets/basegreenhouse/home_police.png">
          <span style="vertical-align: middle;color: white; font-size: 16px;">报警信息</span>
        </el-menu-item>

      </el-menu>
    </div>
    <el-dialog style="font-size: 16px; color:#333; position: absolute;top: -68px; left: 450px;border-radius: 6px"
            :visible.sync="dialogVisible"
            width="620px"
            :show-close="false">
      <div style="margin: -45px 17px 13px 22px;">
        <span style="line-height: 36px;">报警信息</span>
        <el-button class="close" @click="dialogVisible = false">关闭</el-button>
        <el-button v-show="this.warnList.length>0" class="qingkong" @click="getQingKong()" >清空记录</el-button>
      </div>
      <hr style="padding: 0;margin: 0;background: #E5E5E5;height:0.8px; border:none;"/>
      <p v-show="this.warnList.length<=0" style="text-align: center;color: #333333">暂无数据</p>
      <ul  v-for="item in warnList"  :key="item.id">
        <li  style="width: 580px;height: 68px;margin:0 20px 10px -20px;background: #F7F7F7;border-radius: 6px;list-style: none">
          <el-image  style="float: left;margin: 9px 15px 9px 9px;box-sizing: content-box;vertical-align:middle;" :src="require('../assets/basegreenhouse/pop_police.png')" />
          <div style="float: left">
            <p style="font-size: 16px;color: #333;padding: 0;margin: 8px  0 0 0;">{{item.name}}</p>
            <p style="font-size: 14px;color: #999999;padding: 0;margin: 10px 0 0 0;">{{item.remark}}</p>
          </div>
          <div style="text-align:right;float: right;margin-right: 19px;">
            <button @click="getChakan(item.id)"  style="background: none;border: none;color: #009E4F;font-size: 14px;margin-top: 8px;padding: 0;">查看</button>
            <span style="font-size: 12px; color: #999999;display: block;margin-top: 10px;">{{item.createTime}}</span>
          </div>
        </li>

      </ul>
    </el-dialog>
  </div>

</template>
<script type="text/javascript">
  import moment from 'moment';
export default {
  name: "qiheNavMenu",
  data() {
    return {
      activeIndex: '/',
      username: '',
      nickName: '',
      NavHead: '',
      dialogVisible:false,
      day:new Date(),
      warnList:[],//报警信息
      size:''//未读
    };
  },
  watch: {
    "$route"(val, oldval) {
      setTimeout(() => {
        if (val.path !== oldval.path) {
          this.NavHead = localStorage.getItem('changeNavHead')
          if (localStorage.getItem('user_nongye')) {
            this.companyName = JSON.parse(localStorage.getItem('user_nongye')).username
          }
        }

      }, 100)

    }
  },
  filters:{
    timeFormater(value,type='YYYY-MM-DD HH:mm:ss'){
      return moment(value).format(type);

    }
  },
  mounted: function () {
    // setTimeout(() => {

    this.nickName=localStorage.getItem('nickName')
    console.log("用户信息",this.nickName)
    this.NavHead = localStorage.getItem('changeNavHead')
    if (localStorage.getItem('user_nongye')) {
      this.companyName = JSON.parse(localStorage.getItem('user_nongye')).username
    }
    if (localStorage.getItem('url_wuliawnag')) {
      this.activeIndex = localStorage.getItem('url_wuliawnag')
    }
    this.getAllAlarmList()
    // }, 500)

  },
  computed: {},
  methods: {
    //清空
    getQingKong(){
      this.$api.get('error', {}, result => {
          this.getAllAlarmList()
          this.dialogVisible=false

      })
    },
    //查看
    getChakan(id){
      this.$router.push('/qiheWarn')
    },
    getAllAlarmList(){
      this.$api.get('error/getAllAlarm', {}, result => {
          this.warnList=result.list
          this.size=result.size
      })
    },
    handleSelect(key, keyPath) {
      console.log('8989',key, keyPath);
      this.$router.push(key)
      localStorage.setItem('selectIndex', '1')
      localStorage.setItem('url_wuliawnag', key)
      if (key == '/') {
        localStorage.setItem('changeNavHead', '')
      }
    },
    // selectMenuItem(path) {
    //   // this.active = path
    //   window.sessionStorage.setItem("activerouter", path);
    //   // this.activerouter = path
    //   // window.sessionStorage.setItem("activerouter", path)
    //   console.log("mountednav-----",window.sessionStorage.getItem("activerouter") )
    // },
    handleClose(done) {
      this.$confirm('确认关闭？')
              .then(_ => {
                done();
              })
              .catch(_ => {});
    },
    fanhui(){
      this.$router.push('/qiheIndex')
      this.$bas.$emit("suiji",'/qiheIndex')
    },
    tuichu(){
      this.$router.push('/')
      this.$bas.$emit("suiji",'/qiheIndex')
    }
  }
}
</script>

<style scoped>

@media (max-width: 1550px) {
  .line {
    /*margin-left: 20px;*/
    width: 1200px;
    margin: 0 auto;
  }
}

@media (min-width: 1550px) {
  .line {
    margin: 0 auto;
    background: transparent;
    width: 100%;
    height: 73px;
  }
}
.line ul li{
  color: transparent !important;
  border-bottom-color: transparent !important;

}
/deep/.line ul li .fanhui:hover,
.el-menu--horizontal>.el-menu-item .fanhui:not(.is-disabled):focus{
  background-image: url("../assets/basegreenhouse/home_return_bottom.png") !important;
}
.line ul li:hover,
.el-menu--horizontal>.el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal>.el-submenu .el-submenu__title:hover{
  background: none !important;
}

/deep/.el-submenu.is-active .el-submenu__title{
  border: none;
}
/deep/.el-menu--horizontal>.el-submenu.is-active .el-submenu__title{
  border-bottom:none;
  color:white;
}
/deep/.el-menu--horizontal>.el-submenu .el-submenu__title{
  border-bottom: none;
}
.head-ttile-name {
  font-size: 16px;
  color: #FFFFFF;
  text-decoration: none;
  line-height: 73px;
  margin-left: 20px;
}
#headTitle1 .is-active{
  background:none;
  background-image: none;
}
.el-menu--horizontal>.el-menu-item{
  height: 73px !important;
  line-height: 73px !important;
}
.el-menu-item.is-disabled {
  opacity: 1 !important;
}
.el-menu-item .is-active{
  color: transparent !important;
  border-bottom-color: transparent !important;
}
.el-menu{
  background-color: transparent !important;
}
.el-menu.el-menu--horizontal {
  border-bottom: none !important;
}
.el-menu-item .daping:hover{
    background: none !important;
}
.el-menu--horizontal>.el-submenu .el-submenu__title{
  height: 73px !important;
  line-height: 73px !important;
  font-size: 16px;
}
.el-menu--popup{
  box-shadow:none !important;
  padding: 0  !important;
}
.el-menu--horizontal .el-menu .el-menu-item, .el-menu--horizontal .el-menu .el-submenu__title{
  height: 50px !important;
  line-height: 50px !important;
  border-radius:10px  !important;

}
.el-menu--popup-bottom-start{
  margin-top: 0 !important;
}
/deep/.fanhui{
  width: 222px !important;
  height: 73px !important;
  background-image: url("../assets/basegreenhouse/home_return_bottom.png") !important;
}

::v-deep .el-icon-menu:before{
  content: url("../assets/basegreenhouse/home_head.png") !important;
  vertical-align: middle;
  display: inline-block;
  margin-right: 10px;
}
::v-deep.el-menu--horizontal>.el-submenu .el-submenu__title{
  height: 73px;
  line-height: 73px;

}

  ::v-deep .el-menu--horizontal>.el-submenu .el-submenu__title:hover{
    background: transparent;
  }
  ::v-deep .el-menu--horizontal>.el-submenu .el-submenu__icon-arrow {

    margin-top: 0px;
  }
  ::v-deep .el-submenu__title i {
    color: white;
  }
  ::v-deep .el-submenu__icon-arrow {
   font-size: 18px;
  }
  ::v-deep .el-icon-close:before{
    content: none;
  }
  .el-button{
    width: 86px;
    height: 36px;
    border: none;
    float: right;
    padding: 0;
    margin: 0;
    font-size: 12px;

  }
  .close{
    background: url("../assets/basegreenhouse/btn_green_small.png") no-repeat;
    margin-left: 14px;
    color: #009E4F;
  }
  .close:hover{
    color: #009E4F;
  }
  .qingkong{
    background: url("../assets/basegreenhouse/btn_green_confirm_small.png") no-repeat;
    color: white;
  }
  .qingkong:hover{
    color: white;
  }
  ::v-deep .el-dialog__body{
      padding: 30px 0;
  }
</style>
