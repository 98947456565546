var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('div',{staticClass:"body"},[(!( _vm.path==='/login' )&&( _vm.path==='/qiheIndex' ))?_c('head-nav-menu'):_vm._e(),(!( _vm.path==='/login' )&&( _vm.path==='/qiheIndex' )
        ||!( _vm.path==='/login' )&&( _vm.path==='/grennhouse' )
        ||!( _vm.path==='/login' )&&( _vm.path==='/home/grennhouseDetails' )
        ||!( _vm.path==='/login' )&&( _vm.path==='/qiheHistory' )
        ||!( _vm.path==='/login' )&&( _vm.path==='/qiheHistoryDetails' )
        ||!( _vm.path==='/login' )&&( _vm.path==='/qiheWarn' )
        ||!( _vm.path==='/login' )&&( _vm.path==='/home/qiheGrennhouseDetails' )
        )?_c('qiheNavMenu'):_vm._e(),(!( _vm.path==='/login' )&&( _vm.path==='/qiheIndex' )
        ||!( _vm.path==='/login' )&&( _vm.path==='/grennhouse' )
        ||!( _vm.path==='/login' )&&( _vm.path==='/home/grennhouseDetails' )
        ||!( _vm.path==='/login' )&&( _vm.path==='/qiheHistory' )
        ||!( _vm.path==='/login' )&&( _vm.path==='/qiheHistoryDetails' )
        ||!( _vm.path==='/login' )&&( _vm.path==='/qiheWarn' )
        ||!( _vm.path==='/login' )&&( _vm.path==='/home/qiheGrennhouseDetails'
        )

    )?_c('qihe-left-menu',{on:{"aaa":_vm.bbb}}):_vm._e(),(_vm.isRouterAlice)?_c('router-view',{class:_vm.path ==='/login' ? 'page2' :'page'}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }