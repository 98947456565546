import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        user: {}, //登录获取的信息
        NavHead: ''

    },
    getters: {
        getTotal(state) {
            return state.totalPrice * 2
        }
    },
    mutations: {
        STORAGE_USER: (state, data) => {
            state.user = data
        },
        CHANGE_NAVHEAD: (state, data) => {
            state.NavHead = data
        }
    },
    actions: {
        storageUser({commit}, res) {
            console.log(res, 'res*******')
            commit("STORAGE_USER", res)
        },
        changeNavHead({commit}, res) {
            console.log('跳****',res)
            commit('CHANGE_NAVHEAD', res)
        }
    }
})

export default store
