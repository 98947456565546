exports.install = function (Vue, options) {
    console.log(options)
    Vue.prototype.$getPermission = function (data) {
        var list = localStorage.getItem('permissionList')
        list = JSON.parse(list)
        if (list != null) {
            let idx = list.findIndex((item) => item === data)
            if (idx === -1) {
                return false
            } else {
                return true
            }
        } else {
            return false
        }

    };

};
